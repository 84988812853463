<template lang="pug">
router-view
</template>

<script>
export default {
  components: {
  },
  setup() {
  },
}
</script>

<style lang="stylus">
  @import './styles/reset.styl'
  @import './styles/UIKibrary.css'

  .confirm-button
    background linear-gradient(78.93deg, #FF333A 8.18%, #FF6544 91.82%)
    border-radius 1rem
    font-size 0.16rem
    color #fff
    text-align center
    height 0.5rem
    line-height @height
    font-weight 500
    &.disabled
      opacity 0.5
</style>
