export default {
  extends: [
    'jsLoadReady',
    'isLogin',
    'login',
    'downloadImageToAlbum',
    'toast',
    'request',
    'startPageLoading',
    'stopPageLoading',
    'openWebview',
    'closeWebview',
    'openDeeplink',
  ],
  events: [
    'setGoodInfo',
    'setVideoState',
  ],
}
