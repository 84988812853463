import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './bridge/ydkInit'
import 'vant/lib/index.css';

function init() {
  if (window.needDebug && process.env.VUE_APP_MODE === 'test') {
    const script = document.createElement('script')
    script.src = 'https://shared.youdao.com/js/console/vconsole3.4.0.min.js'
    document.body.appendChild(script)
    script.onload = () => {
    // eslint-disable-next-line
    const vconsole = new VConsole()
    }
  }
}

// eslint-disable-next-line import/no-extraneous-dependencies
// import('vconsole').then((module) => {
//   const VConsole = module.default;
//   // eslint-disable-next-line no-new
//   new VConsole();
// });

init()

createApp(App).use(router).use(store).mount('#app');
