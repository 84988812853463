import { createStore } from 'vuex';

export default createStore({
  state: {
    childYid: '',
    goodId: '',
  },
  mutations: {
    setchildYid(state, id) {
      state.childYid = id
    },
    setGoodId(state, id) {
      state.goodId = id
    },
  },
  actions: {
    setchildYid({ commit }, data) {
      commit('setchildYid', data);
    },
    setGoodId({ commit }, data) {
      commit('setGoodId', data);
    },
  },
  modules: {
  },
});
