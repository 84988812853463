import jsApiList from './jsApiList'

const { ydk } = window

ydk.config({
  debug: false,
  jsApiList: [
    ...jsApiList.extends,
    ...jsApiList.events,
  ],
})

const extendObj = {}
/* eslint-disable no-underscore-dangle */
jsApiList.extends.forEach((api) => {
  extendObj[api] = (params = {}, successCb, failCb) => {
    if (process.env.VUE_APP_MODE === 'dev' && !window.ydk.isYD) {
      console.log(api, params)
      if (successCb) {
        successCb({})
      }
      return
    }
    const promiseParams = {
      ...params,
      success(res) {
        if (process.env.VUE_APP_MODE !== 'prod' && process.env.VUE_APP_MODE !== 'cms') {
          console.log(api, ' success', res)
        }
        if (successCb) {
          successCb(res.data)
        }
      },
      fail: failCb,
    }
    ydk._invoke(api, promiseParams, promiseParams)
  }
})

jsApiList.events.forEach((api) => {
  extendObj[api] = (params = {}, successCb, failCb) => {
    if (process.env.VUE_APP_MODE === 'dev') {
      window[api] = successCb
      return
    }
    const promiseParams = {
      ...params,
      success(res) {
        if (process.env.VUE_APP_MODE !== 'prod' && process.env.VUE_APP_MODE !== 'cms') {
          console.log(api, ' success', res)
        }
        if (successCb) {
          successCb(res.data, res.responseCallback)
        }
      },
      fail: failCb,
    }
    ydk._on(api, promiseParams, promiseParams)
  }
})

ydk.extend(extendObj)
