const ua = navigator.userAgent;
function isIOS9() {
  const getOsv = () => {
    const reg = /OS ((\d+_?){2,3})\s/;
    if (
      ua.match(/iPad/i)
      || navigator.platform.match(/iPad/i)
      || ua.match(/iP(hone|od)/i)
      || navigator.platform.match(/iP(hone|od)/i)
    ) {
      const osv = reg.exec(navigator.userAgent);
      if (osv.length > 0) {
        return osv[0].replace('OS', '')
          .replace('os', '')
          .replace(/\s+/g, '')
          .replace(/_/g, '.');
      }
    }
    return '';
  };
  const osv = getOsv();
  const osvArr = osv.split('.');
  if (osvArr && osvArr.length > 0) {
    if (parseInt(osvArr[0], 10) >= 9) {
      return true;
    }
  }
  return false;
}

const browser = {
  versions: {
    isIOS9: isIOS9(),
    trident: ua.indexOf('Trident') > -1, // IE内核
    presto: ua.indexOf('Presto') > -1, // opera内核
    webKit: ua.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
    gecko: ua.indexOf('Gecko') > -1 && ua.indexOf('KHTML') === -1, // 火狐内核
    mobile: !!ua.match(/AppleWebKit.*Mobile.*/) || !!ua.match(/AppleWebKit/), // 是否为移动终端
    ios: !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios 端
    android: ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1, // android端或者uc浏览器
    iPhone: ua.indexOf('iPhone') > -1 || ua.indexOf('Mac') > -1, // 是否为iPhone或者QQHD浏览器
    iPad: ua.indexOf('iPad') > -1, // 是否iPad
    isWeibo: ua.indexOf('WeiBo') > -1,
    webApp: ua.indexOf('Safari') === -1, // 是否web应该程序，没有头部与底部
  },
  language: (navigator.browserLanguage || navigator.language).toLowerCase(),
};

const getParameter = (name) => {
  const r = new RegExp(`(\\?|#|&)${name}=([^&#]*)(&|#|$)`);
  const m = window.location.href.match(r);
  return decodeURIComponent(!m ? '' : m[2]);
};
const isAndroid = /android/i.test(ua.toLowerCase() || '');
const isWx = ua.toLowerCase().includes('micromessenger');

function copy(text) {
  return new Promise((resolve, reject) => {
    // Copy textarea, pre, div, etc.
    const el = document.createElement('div')
    el.innerText = text
    el.setAttribute('style', 'position:absolute;left:-9999px')
    document.body.appendChild(el)
    if (document.body.createTextRange) {
      // IE
      const textRange = document.body.createTextRange()
      textRange.moveToElementText(el)
      textRange.select()
      textRange.execCommand('Copy')
      document.body.removeChild(el)
      resolve()
      return
    }
    if (window.getSelection && document.createRange) {
      // non-IE
      const { editable, readOnly } = el
      el.setAttribute('contentEditable', true)
      el.setAttribute('readOnly', false)
      const range = document.createRange()
      range.selectNodeContents(el)
      const sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)
      if (el.nodeName.toUpperCase() === 'TEXTAREA' || el.nodeName.toUpperCase() === 'INPUT') {
        el.select()
      }
      if (el.setSelectionRange && navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        el.setSelectionRange(0, 999999)
      }
      el.setAttribute('contentEditable', editable)
      el.setAttribute('readOnly', readOnly)
      if (document.queryCommandSupported('copy')) {
        const successful = document.execCommand('copy')
        document.body.removeChild(el)
        if (successful) {
          resolve()
        } else {
          reject()
        }
      } else {
        reject()
      }
      document.body.removeChild(el)
      return
    }
    document.body.removeChild(el)
    reject()
  })
}

export default {
  ua,
  browser,
  getParameter,
  isAndroid,
  isWx,
  copy,
};
