import { createRouter, createWebHashHistory } from 'vue-router'
import utils from '../utils'

const routes = [
  {
    path: '/',
    name: 'index',
    meta: { title: '课程领取' },
    component: () => import('../views/Index.vue'),
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/Detail.vue'),
  },
  {
    path: '/claim',
    name: 'claim',
    meta: { title: 'AI提升营' },
    component: () => import('../views/Claim.vue'),
  },
  {
    path: '/phone',
    name: 'phone',
    meta: { title: '手机号确认' },
    component: () => import('../views/Phone.vue'),
  },
  {
    path: '/receive',
    name: 'receive',
    meta: { title: '免费课领取' },
    component: () => import('../views/FreeReceive.vue'),
  },
  {
    path: '/free',
    name: 'free',
    meta: { title: '免费课领取' },
    component: () => import('../views/Free.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const { title = '' } = to.meta
  document.title = title
  if (to.name === 'index' && window.ydk.isYD) {
    next({
      name: 'claim',
      query: {
        code: utils.getParameter('code'),
      },
    })
    return
  }
  next()
})

export default router
